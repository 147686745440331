import React from "react"
import TitleBlock from "./blocks/TitleBlock";
import Layout from "../components/layout"
import Blocks from "./blocks/Blocks"
import SEO from "../components/seo"
import SliderPage from "../components/SliderPage"
import { Link } from "gatsby"

import tools from "../images/pictos/tools.png"
import calendar from "../images/pictos/calendar.png"
import locationImg from "../images/pictos/location.png"
import roofs from "../images/pictos/roofs.png"

import "../styles/main.scss"

const Achievement = props => {
    let { achievement, media, allAchievements } = props.pageContext;
    let { title, titleSEO, backgroundImage, date, location, materials, description, blocks, category, descriptionSEO } = achievement
    allAchievements = allAchievements.filter(a => a.category === category && a.id !== achievement.id);
    allAchievements.slice(0, 6)
    let page = {};
    page.blocks = blocks ? blocks.sort((a, b) => a.ordre - b.ordre) : [];
    page.url = props.url;
    page.ariane = [
        { title: "Accueil", url: "/" },
        { title: "Réalisations", url: "/realisations" },
        { title: title, url: page.url },
    ]

    switch (category) {
        case "ACCES_DIFFICILE":
            category = 'Accès difficile'
            break;
        case "AMIANTE":
            category = 'Amiante'
            break;
        case "APPORT_DE_LUMIERE":
            category = 'Apport de lumière'
            break;
        case "DESENFUMAGE":
            category = 'Désenfumage'
            break;
        case "ISOLATION":
            category = 'Isolation'
            break;
        case "RENOVATION":
            category = 'Rénovation'
            break;
        case "REPARATION":
            category = 'Réparation'
            break;
        default:
            category = 'Sécurisation'
            break;
    }

    return (
        <Layout ariane={page.ariane}>
            <SEO titleSEO={titleSEO} description={descriptionSEO} />
            <div className="achievement">
                <TitleBlock media={media} data={{ title, backgroundImage, balise: "H1" }} />
                <div className="presentation">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="d-flex flex-column">
                                    <div className="d-flex">
                                        <img src={calendar} alt="date" className="img-fluid" width="120px" height="120px" />
                                        <p className="pt-5"><span className="asap-bold">Date :</span> {date.split('-')[0]}</p>
                                        <Link to="/realisations" state={{ category: category }}>{category}</Link>
                                    </div>
                                    <div className="d-flex">
                                        <img src={locationImg} alt="lieu" className="img-fluid" width="120px" height="120px" />
                                        <p className="pt-5"><span className="asap-bold">Localisation :</span> {location}</p>
                                    </div>
                                    <div className="d-flex">
                                        <img src={roofs} alt="tuiles" className="img-fluid" width="120px" height="120px" />
                                        <p className="pt-5"><span className="asap-bold">Matériaux :</span> {materials}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="d-flex">
                                    <img src={tools} alt="outils" width="120px" height="120px" />
                                    <p className="asap-bold pt-5">Descriptif des travaux : <div dangerouslySetInnerHTML={{ __html: description }} /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Blocks page={page} media={media} />
                {allAchievements.length !== 0 && (<SliderPage title={`Autres réalisations`} baseUrl={`/realisations/`} items={allAchievements} media={media} />)}
            </div>
        </Layout>
    )
}

export default Achievement